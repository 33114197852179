.rc-slider {
  margin: 1rem 0;
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 5px;
  border-radius: 6px;
}
.rc-slider-rail-0 {
  background: rgba(255, 255, 255, 0.38) !important;
}
.rc-slider-rail-2 {
  background: #2eda76;
  background: linear-gradient(90deg, #2eda76 10%, #6d6c6c 18%);
}
.rc-slider-rail-15 {
  background: #2eda76;
}

.rc-slider-rail-8 {
  background: #2eda76;
  background: linear-gradient(90deg, #2eda76 45%, #6d6c6c 50%);
}
.rc-slider-handle {
  position: absolute;
  bottom: -5px;
  width: 15px;
  height: 15px;
  background-color: #1b998b;
  border: solid 2px tint(#1b998b, 50%);
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 1;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: tint(#2db7f5, 20%);
  box-shadow: 0 0 0 5px tint(#2db7f5, 50%);
}
.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}
.rc-slider-handle:focus-visible {
  border-color: #2db7f5;
  box-shadow: 0 0 0 3px tint(#2db7f5, 50%);
}
.rc-slider-handle-click-focused:focus {
  border-color: tint(#2db7f5, 50%);
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: tint(#2db7f5, 20%);
}
.rc-slider-handle:active {
  border-color: tint(#2db7f5, 20%);
  box-shadow: 0 0 5px tint(#2db7f5, 20%);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.rc-slider-mark-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #666;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 10px;
  word-wrap: break-word;
  transform: none !important;
}
.rc-slider-mark-text-active {
  color: rgba(255, 255, 255, 0.82);
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 10px;
  background: transparent;
  pointer-events: none;
}
