@tailwind base;
/* * {
  scrollbar-width: thin;
  scrollbar-color: #000  #383742;
} */
*::-webkit-scrollbar {
  width: 1px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #242233;
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.38);
  border-radius: 2px;
  /* border: 1px solid #000; */
}

*::-webkit-scrollbar:horizontal {
  width: 1px;
  background-color: #4a4761;
  border-radius: 5px;
  /* border: 1px solid #000; */
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.test-txt-prompt::-webkit-scrollbar {
  display: none;
}
.stems-options::-webkit-scrollbar {
  display: none;
}
.scrollable-projects::-webkit-scrollbar {
  display: none;
}

@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  max-height: 100vh !important;
  height: 100vh !important;
  background-color: #444;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.timeline-slider[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

.timeline-slider[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.timeline-slider[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.timeline-slider[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.react-joyride__overlay {
  overflow: hidden;
  height: 100vh !important;
  bottom: 0;
}

wave {
  overflow: hidden !important;
}

#preview-tracks canvas {
  pointer-events: auto !important;
  cursor: pointer !important;
}

.react-multi-email {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid #ff659c;
  border-radius: 10px;
}
.react-multi-email.empty > span[data-placeholder] {
  color: rgba(255, 255, 255, 0.82) !important;
}
.react-multi-email > input {
  background: none !important;
  color: #fff !important;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}
@layer utilities {
  .border-gradient {
    border-width: 3.5px;
    border-image: linear-gradient(to right, #e9238e, #4729ff) 1;
  }
}
.Toastify__toast-container--top-center {
  position: absolute !important;
  top: 0px !important;
}
.Toastify__toast-container--bottom-center {
  position: absolute !important;
  bottom: 10px !important;
}

.loader {
  width: 35px;
  --b: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  margin-top: 2px;
  background: conic-gradient(#0000 10%, #fff) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}
.slider {
  display: flex !important;
  align-items: center !important;
  justify-items: center !important;
  gap: 10px !important;
  padding: 0px 6px !important;
}
.control-dots {
  position: absolute !important;
  bottom: -10px !important;
}
.mySwiper {
  width: 100% !important;
  padding: 0px 0px 25px 0px !important;
}
.swiper-pagination {
  position: absolute !important;
  bottom: -10px !important;
  padding: 0px 0px 5px 0px !important;
}
.swiper-pagination-bullet {
  background-color: white !important;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}
.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 5px;
}
.slick-next,
.slick-prev {
  background-color: transparent !important;
}
.slick-dots li button:before {
  color: white !important;
}
.share-option-container::-webkit-scrollbar {
  display: none;
}
video::-webkit-media-controls {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .slider-container {
    padding: 0px 2px 0px 2px !important;
  }
}

/* styles for previewer page */
.custom-height {
  max-height: calc(100vh - 42rem);
}

@media (min-width: 320px) and (max-width: 480px) {
  .custom-height {
    max-height: calc(100vh - 33rem);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .custom-height {
    max-height: calc(100vh - 28rem);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-height {
    max-height: calc(100vh - 41rem);
  }
}
